import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Routes/router";
import Main from "./Components/Layout/main";
import "./App.css";
import CustomCursor from "./Components/CustomCursor";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

ReactGA.initialize("G-8JJ0114X05");
TagManager.initialize({
  gtmId: "GTM-WBZXRWXC",
});

function App() {
  return (
    <div>
      <TawkMessengerReact
        propertyId="665de137981b6c5647780b24"
        widgetId="1hvfbfg87"
      />
      <CustomCursor />
      <BrowserRouter>
        <Main>
          <Router />
        </Main>
      </BrowserRouter>
    </div>
  );
}

export default App;
