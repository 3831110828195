import React, { useEffect, useState } from "react";
import companyLogo from "../../Assets/Images/white and purple.png";
import { Link, useNavigate } from "react-router-dom";
import "../../Assets/Styles/layout.css";
import { IoChevronDownSharp } from "react-icons/io5";
import { Button } from "antd";
import { HiOutlineBars3 } from "react-icons/hi2";
import { CgClose } from "react-icons/cg";

const Navbars = ({ selectedLink, setSelectedLink }) => {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const handleLinkClick = (e) => {
    navigate(e);
    setSelectedLink(e);
    document.getElementById("myNav").style.height = "0%";
  };

  const handleLogoClick = () => {
    navigate("/home");
    setSelectedLink("/home");
  };

  function openNav() {
    document.getElementById("myNav").style.height = "100%";
  }

  function closeNav() {
    document.getElementById("myNav").style.height = "0%";
  }

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 20) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    changeBackground();

    window.addEventListener("scroll", changeBackground);
  }, []);

  return (
    <div>
      <nav
        className={navbar ? "navbar-active navbar" : "navbar-not-active navbar"}
      >
        <div onClick={handleLogoClick} className="navbar-logo">
          <img src={companyLogo} alt="CouSync Logo" />
        </div>

        <div id="myNav" class="overlay">
          <div className="closebtn" onClick={() => closeNav()}>
            <CgClose />
          </div>
          <ul className="navbar-links">
            <li onClick={() => handleLinkClick("/home")}>
              <Link
                className={
                  selectedLink === "/home" || selectedLink === "/"
                    ? "nav-item-selected"
                    : "nav-item"
                }
              >
                Home
              </Link>
            </li>
            <li onClick={() => handleLinkClick("/about")}>
              <Link
                className={
                  selectedLink === "/about" ? "nav-item-selected" : "nav-item"
                }
              >
                About Us
              </Link>
            </li>
            <li onClick={() => handleLinkClick("/services")}>
              <Link
                className={
                  selectedLink === "/services"
                    ? "nav-item-selected"
                    : "nav-item"
                }
              >
                Services
                <IoChevronDownSharp className="tech-icon" />
              </Link>
            </li>
            <li onClick={() => handleLinkClick("/technologies")}>
              <Link
                className={
                  selectedLink === "/technologies"
                    ? "nav-item-selected"
                    : "nav-item"
                }
              >
                Technologies <IoChevronDownSharp className="tech-icon" />
              </Link>
            </li>
            <li onClick={() => handleLinkClick("/portfolio")}>
              <Link
                className={
                  selectedLink === "/portfolio"
                    ? "nav-item-selected"
                    : "nav-item"
                }
              >
                Our Portfolio
              </Link>
            </li>
            <li onClick={() => handleLinkClick("/careers")}>
              <Link
                className={
                  selectedLink === "/careers" ? "nav-item-selected" : "nav-item"
                }
              >
                Careers
              </Link>
            </li>
            <li>
              <Button
                className="btn-touch"
                onClick={() => handleLinkClick("contact")}
              >
                Get in Touch
              </Button>
            </li>
          </ul>
        </div>

        <div className="collapse" onClick={() => openNav()}>
          <HiOutlineBars3 className="collapse-icon" />
        </div>
      </nav>
    </div>
  );
};

export default Navbars;
