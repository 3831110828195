import { Col, Row } from "antd";
import React, { useEffect } from "react";
import "../Assets/Styles/technologies.css";
import EndSection from "../Components/endSection";
import { TechnologiesList } from "../Utils/ technologies";

function Technologies(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="technologies">
      <h1 className="title">Technologies</h1>
      <h2 className="subtitle">
        Revolutionizing Software Development with Leading Technologies
      </h2>
      <div className="line-sep" />
      <div className="container">
        <Row gutter={[20, 20]} justify="center">
          {TechnologiesList?.map((item) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <div
                className="card"
                onDoubleClick={() => window.open(item?.link, "_blank")}
              >
                <h1>{item?.name}</h1>
                <div>
                  <img src={item?.photo} alt={item?.name} />
                  <p>{item?.details}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <EndSection />
    </div>
  );
}

export default Technologies;
