import { FaAccusoft, FaQq, FaQrcode } from "react-icons/fa";
import AI from "../Assets/Images/AI.png";
import Desktop from "../Assets/Images/Desktop.png";
import Mobile from "../Assets/Images/Mobile.png";
import UIUX from "../Assets/Images/UIUX.png";
import GenAI from "../Assets/Images/GenerativeAi.png";
import WebDev from "../Assets/Images/webDev.jpg";
import Devops from "../Assets/Images/DevOpsNew.png";
import DbDesign from "../Assets/Images/dbDesign.jpg";
import ArchitectureDesign from "../Assets/Images/ArchitectureDesign.png";
import DigitalMarketting from "../Assets/Images/DigitalMarketting.png";

export const Services = [
  {
    key: "1",
    icon: <FaQrcode color="white" />,
    photo: UIUX,
    label: "UI/UX Designing",
    information:
      "Revolutionize your digital journey with our UI/UX design services, where we seamlessly blend beauty and usability. Engineered for effortless navigation, our designs captivate users, ensuring an immersive and visually stunning online adventure. Uncover groundbreaking solutions that harmonize form and function, delivering an unparalleled digital experience.",
  },
  {
    key: "2",
    icon: <FaQq color="white" />,
    photo: WebDev,
    label: "Web Application Development",
    information:
      "Elevate your digital presence with our expert web app development services. We specialize in crafting custom solutions that seamlessly blend innovation and functionality. From conceptualization to deployment, our team ensures the development of robust and user-centric web applications, empowering your business to thrive in the digital landscape. Explore the possibilities as we transform your ideas into dynamic and responsive online experiences.",
  },
  {
    key: "3",
    photo: Mobile,
    icon: <FaAccusoft color="white" />,
    label: "Mobile Application Development",
    information:
      "Unlock the potential of mobile technology with our cutting-edge mobile application development services. We specialize in creating dynamic and user-friendly apps tailored to meet your unique business goals. From concept to deployment, our expert team ensures a seamless and engaging mobile experience. Empower your audience with innovative solutions designed to thrive in the ever-evolving mobile landscape. Explore the future of mobile applications with us.",
  },
  {
    key: "4",
    icon: <FaAccusoft color="white" />,
    label: "Desktop Application Development",
    photo: Desktop,
    information:
      "Embark on a journey of efficiency and dependability through our desktop application development services. Customized to fit your business requirements, we forge powerful and user-centric desktop solutions, elevating productivity to new heights. Our expert team guarantees flawless integration, intuitive interfaces, and peak performance, empowering your workflow. Immerse yourself in the ease of personalized desktop solutions, meticulously crafted to meet the distinctive demands of your business environment.",
  },
  {
    key: "5",
    icon: <FaAccusoft color="white" />,
    photo: DbDesign,
    label: "Database Designing",
    information:
      "Optimize your data infrastructure with our expert database design services. We specialize in crafting scalable and efficient database solutions tailored to your business requirements. From data modeling to implementation, our team ensures robust architectures that drive performance and reliability. Unlock the full potential of your data with our strategic and innovative database design, providing a solid foundation for your business operations.",
  },
  {
    key: "6",
    icon: <FaAccusoft color="white" />,
    photo: ArchitectureDesign,
    label: "Architecture Designing",
    information:
      "Transform your vision into architectural masterpieces with our bespoke design services. We specialize in crafting innovative and functional architectural solutions tailored to your unique needs. From conceptualization to execution, our team brings creativity and precision to every project. Explore the fusion of aesthetics and functionality as we design spaces that inspire and endure. Discover the art of architectural excellence and collaboration with our dedicated team at Cousync.",
  },
  {
    key: "7",
    icon: <FaAccusoft color="white" />,
    photo: GenAI,
    label: "Generative AI",
    information:
      "Embark on a journey of creativity and innovation with our generative AI services. Harnessing the power of advanced algorithms, we enable machines to generate unique and dynamic content. From art to text, our generative AI solutions redefine possibilities. Experience the synergy of human creativity and artificial intelligence, unlocking new dimensions of innovation for your business. Explore the transformative potential of generative AI with our expert team.",
  },
  {
    key: "8",
    icon: <FaAccusoft color="white" />,
    label: "Digital Marketing",
    photo: DigitalMarketting,
    information:
      "Elevate your online presence with our comprehensive digital marketing services. From strategic planning to execution, we specialize in maximizing your digital reach. Our team employs data-driven strategies across various platforms, optimizing campaigns for measurable results. Whether it's SEO, social media, or targeted advertising, we craft tailored solutions to amplify your brand's visibility and engagement. Join us in navigating the dynamic landscape of digital marketing and achieve your business goals with impact.",
  },
  {
    key: "9",
    icon: <FaAccusoft color="white" />,
    photo: AI,
    label: "Machine Learning/AI",
    information:
      "Explore the forefront of innovation with our machine learning and AI services. We leverage advanced algorithms to empower your business with intelligent solutions. From predictive analytics to natural language processing, our expertise spans the spectrum of machine intelligence. Uncover actionable insights, automate processes, and stay ahead in the era of smart technology. Elevate your business with our cutting-edge machine learning and AI capabilities.",
  },
  {
    key: "10",
    icon: <FaAccusoft color="white" />,
    label: "DevOps",
    photo: Devops,
    information:
      "Optimize your development lifecycle with our comprehensive DevOps services. We seamlessly integrate development and operations, fostering collaboration and efficiency throughout the software delivery process. From continuous integration to automated deployments, our DevOps solutions enhance agility, reduce time-to-market, and ensure the reliability of your applications. Experience a streamlined and automated development pipeline that empowers your team to deliver high-quality software at scale.",
  },
];
