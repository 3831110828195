import Angular from "../Assets/Images/angular.png";
import AWS from "../Assets/Images/aws.png";
import Azure from "../Assets/Images/azure.png";
import CSharp from "../Assets/Images/csharp.svg";
import DigitalOcean from "../Assets/Images/digitalocean.png";
import Mongo from "../Assets/Images/mongo.png";
import Figma from "../Assets/Images/figma.png";
import SQL from "../Assets/Images/mysql.svg";
import Nest from "../Assets/Images/nest.png";
import Next from "../Assets/Images/nextjs.png";
import Node from "../Assets/Images/nodejs.png";
import Oracle from "../Assets/Images/oracle.png";
import PGSQL from "../Assets/Images/postgresql.png";
import Python from "../Assets/Images/python.png";
import React from "../Assets/Images/react.png";
import Vue from "../Assets/Images/viewjs.png";
import DotNet from "../Assets/Images/microsoftnet.png";
import Tensorflow from "../Assets/Images/Tensorflow.png";
import ScikitLearn from "../Assets/Images/scikitlearn.png";
import OpenCV from "../Assets/Images/openCV.png";
import Keras from "../Assets/Images/keras.png";
import Pytorch from "../Assets/Images/pytorch.png";
import googleCloud from "../Assets/Images/googleCloud.png";
import stripe from "../Assets/Images/stripe.png";
import googleAuth from "../Assets/Images/googleAuth.png";
import firebase from "../Assets/Images/firebase.png";
import swift from "../Assets/Images/swift.png";
import java from "../Assets/Images/java.png";
import twilio from "../Assets/Images/twilio.png";

export const TechnologiesList = [
  {
    name: "AngularJS",
    photo: Angular,
    details:
      "AngularJS, developed by Google, is a dynamic JavaScript framework designed to simplify the process of building dynamic, single-page web applications. Released in 2010, AngularJS introduces a declarative approach to web development, allowing developers to express the behavior of their applications in a clear and organized manner. With its two-way data binding, modular architecture, and extensive set of tools, AngularJS empowers developers to create robust and maintainable web applications with ease. Whether you are a seasoned developer or just starting, AngularJS provides a powerful platform for building modern, interactive web experiences.",
    link: "https://angularjs.org/",
  },
  {
    name: "Amazon Web Services",
    photo: AWS,
    details:
      "Amazon Web Services (AWS) stands as a leading cloud computing platform, offering a comprehensive suite of on-demand services that enable businesses and developers to build, deploy, and scale applications with unparalleled flexibility and efficiency. Launched by Amazon in 2006, AWS provides a vast array of cloud-based solutions, including computing power, storage, databases, machine learning, analytics, and more. Renowned for its reliability, scalability, and cost-effectiveness, AWS has become a cornerstone for organizations seeking to innovate and streamline their IT infrastructure. As a pioneer in cloud computing, AWS continues to play a pivotal role in shaping the landscape of modern technology and business operations.",
    link: "https://aws.amazon.com/",
  },
  {
    name: "Microsoft Azure",
    photo: Azure,
    details:
      "Microsoft Azure, a cloud computing platform crafted by Microsoft, stands at the forefront of empowering organizations to build, deploy, and manage applications seamlessly in the cloud. Launched in 2010, Azure offers a diverse set of services, ranging from virtual computing and storage to artificial intelligence and Internet of Things (IoT). With a global network of data centers, Azure enables businesses to scale their operations efficiently and securely. The platform is known for its robust integration with Microsoft's extensive suite of tools and technologies, providing a comprehensive ecosystem for developers and enterprises alike. As a key player in the cloud computing space, Microsoft Azure continues to play a pivotal role in driving digital transformation and innovation across industries.",
    link: "https://azure.microsoft.com/",
  },
  {
    name: "C#",
    photo: CSharp,
    details:
      "C# (pronounced C-sharp) is a versatile and modern programming language developed by Microsoft. Introduced in the early 2000s as part of the .NET framework, C# has evolved into a powerful, object-oriented language widely used for building a variety of applications, ranging from desktop software to web and mobile applications. Known for its simplicity, type safety, and strong support for object-oriented programming principles, C# has become a popular choice for developers aiming to create robust and scalable software solutions. With features such as automatic memory management, asynchronous programming support, and a rich set of libraries, C# provides a productive and efficient environment for developers across different domains and platforms. Whether you are a beginner or an experienced programmer, C# offers a user-friendly syntax and a vast ecosystem, making it a valuable language in the software development landscape.",
    link: "https://docs.microsoft.com/en-us/dotnet/csharp/",
  },
  {
    name: "Digital Ocean",
    photo: DigitalOcean,
    details:
      "DigitalOcean is a cloud infrastructure provider that has gained popularity for its simplicity, scalability, and developer-friendly approach. Founded in 2011, DigitalOcean offers cloud computing services that include scalable virtual servers known as 'Droplets,' managed databases, object storage, and a variety of developer tools. Catering to both individuals and businesses, DigitalOcean stands out for its straightforward pricing model, user-friendly interface, and quick deployment capabilities. Developers appreciate DigitalOcean's focus on simplicity, making it an accessible choice for those looking to deploy and manage applications in the cloud without unnecessary complexity. With data centers strategically located worldwide, DigitalOcean provides a reliable platform for hosting, scaling, and maintaining web applications and services.",
    link: "https://www.digitalocean.com/",
  },
  {
    name: "Figma",
    photo: Figma,
    details:
      "Figma is a collaborative design and prototyping tool that has revolutionized the way teams create and iterate on user interfaces. Launched in 2015, Figma is known for its cloud-based platform, allowing designers to work seamlessly in real-time, irrespective of their location. With a focus on collaboration, Figma enables multiple users to simultaneously work on a design project, fostering efficient teamwork. The platform supports the creation of interactive prototypes, facilitating user testing and design validation. Its versatility extends to a variety of design tasks, including UI/UX design, user flow mapping, and design system management. Embraced by designers and product teams globally, Figma has become an integral tool in the modern design workflow, offering a flexible and inclusive environment for creating visually compelling and user-friendly digital experiences.",
    link: "https://www.figma.com/",
  },
  {
    name: "Mongo DB",
    photo: Mongo,
    details:
      "MongoDB, a NoSQL database management system, has emerged as a leading solution for handling large volumes of unstructured or semi-structured data. Introduced in 2009, MongoDB is designed to provide scalability, flexibility, and ease of development for applications dealing with diverse data types. Unlike traditional relational databases, MongoDB adopts a document-oriented approach, storing data in flexible, JSON-like BSON (Binary JSON) documents. This schema-less architecture allows for dynamic and iterative development, making it particularly well-suited for projects where data structures evolve over time. MongoDB is revered for its high performance, horizontal scaling capabilities, and comprehensive set of features, including support for geospatial data and powerful querying through its expressive query language. Widely adopted across various industries, MongoDB has become a popular choice for developers and organizations aiming to efficiently manage and retrieve data in a scalable and agile manner.",
    link: "https://www.mongodb.com/",
  },
  {
    name: "MySQL",
    photo: SQL,
    details:
      "MySQL is a widely-used open-source relational database management system (RDBMS) that has played a pivotal role in the development of dynamic web applications. First released in 1995, MySQL is known for its reliability, ease of use, and robust performance. As a relational database, MySQL organizes data into tables, allowing for efficient retrieval and management using Structured Query Language (SQL). It is particularly well-suited for applications that require structured data and transactions. MySQL's versatility extends to its support for various programming languages and platforms, making it a popular choice for developers across different domains. Whether used for small-scale projects or large enterprise applications, MySQL's open-source nature, active community support, and continuous development have contributed to its enduring popularity as a go-to database solution in the software development landscape.",
    link: "https://www.mysql.com/",
  },
  {
    name: "Nest JS",
    photo: Nest,
    details:
      "Nest.js, introduced in 2017, is a progressive Node.js framework designed for building scalable and maintainable server-side applications. Built on top of Express.js, Nest.js incorporates TypeScript for enhanced type safety and a structured development approach. Leveraging a modular and decorator-based architecture inspired by Angular, Nest.js promotes code organization and maintainability. It offers features such as dependency injection, middleware support, and robust testing capabilities. Ideal for developing APIs, microservices, and server-side applications, Nest.js provides a balanced and efficient platform for developers. With its strong integration capabilities and a growing community, Nest.js has gained popularity as a framework that combines the best practices of both Node.js and TypeScript.",
    link: "https://nestjs.com/",
  },
  {
    name: "Next JS",
    photo: Next,
    details:
      "Next.js is a React-based web framework that facilitates the development of modern and efficient web applications. Released by Vercel in 2016, Next.js extends React's capabilities by adding server-side rendering, automatic code splitting, and a simple, convention-based setup. It is designed to enhance both the developer experience and the performance of web applications. Next.js embraces a zero-configuration philosophy, providing a seamless development experience with sensible defaults while remaining highly customizable for advanced use cases. With features like automatic routing, dynamic imports, and a powerful plugin system, Next.js simplifies the process of building complex, dynamic web applications. It has gained popularity for its ability to enable a great user experience through fast page loads, improved SEO, and efficient resource utilization. As an open-source framework, Next.js continues to be widely adopted for building static websites, dynamic web applications, and server-rendered React applications.",
    link: "https://nextjs.org/",
  },
  {
    name: ".NET",
    photo: DotNet,
    details:
      ".NET is a versatile, cross-platform framework by Microsoft for building applications. Supporting various programming languages, including C#, it spans web, desktop, mobile, and cloud development. With components like ASP.NET for web apps and .NET Core offering cross-platform capabilities, .NET provides a powerful and unified platform for diverse application needs.",
    link: "https://dotnet.microsoft.com/",
  },
  {
    name: "Node JS",
    photo: Node,
    details:
      "Node.js is a runtime environment that allows JavaScript to be used for server-side scripting. Introduced in 2009, it has gained widespread popularity for building scalable and high-performance applications. Node.js is event-driven and non-blocking, making it efficient for handling concurrent requests. It is particularly well-suited for developing real-time applications, APIs, and microservices. With a large package ecosystem through npm (Node Package Manager), developers can easily access and integrate various libraries. Node.js has become a go-to choice for building server-side applications using JavaScript, unifying the language across the entire web development stack.",
    link: "https://nodejs.org/",
  },
  {
    name: "Oracle",
    photo: Oracle,
    details:
      "Oracle is a prominent technology company known for its enterprise-level database management systems (DBMS). Established in the late 1970s, Oracle's relational database, Oracle Database, is widely utilized for storing and managing large volumes of structured data. Recognized for its scalability, security features, and support for SQL, Oracle Database is a preferred choice for businesses with demanding data requirements. In addition to databases, Oracle provides an array of enterprise software solutions, cloud services, and hardware products. Its technology stack encompasses applications for various business functions, including ERP and CRM, along with cloud infrastructure services. With a strong foothold in the tech industry, Oracle remains a key player, offering comprehensive technology solutions to address the diverse needs of businesses across the globe.",
    link: "https://www.oracle.com/",
  },
  {
    name: "PostgreSQL",
    photo: PGSQL,
    details:
      "PostgreSQL, commonly known as pgSQL, is a robust open-source relational database management system recognized for its adherence to SQL standards, extensibility, and strong performance. Established in the mid-1980s, PostgreSQL is favored for its ability to handle complex queries and manage large datasets efficiently. With advanced features, including support for custom data types and extensibility through stored procedures, PostgreSQL is a popular choice in the tech industry for applications requiring a reliable and feature-rich relational database solution. Its open-source nature and commitment to standards make PostgreSQL a trusted option for businesses and developers alike.",
    link: "https://www.postgresql.org/",
  },
  {
    name: "Python",
    photo: Python,
    details:
      "Python, created in the late 1980s, is a versatile and high-level programming language celebrated for its readability, simplicity, and extensive community support. Widely used in various domains, including web development, data science, artificial intelligence, and automation, Python's syntax emphasizes code readability, making it an excellent choice for beginners and experienced developers alike. Its vast ecosystem of libraries, frameworks, and tools, such as Django and Flask for web development, NumPy and Pandas for data science, and TensorFlow for machine learning, contributes to its widespread adoption. Python's cross-platform compatibility and ease of integration with other languages further enhance its appeal, solidifying its position as one of the most popular and versatile programming languages in the tech industry.",
    link: "https://www.python.org/",
  },
  {
    name: "React JS",
    photo: React,
    details:
      "React.js, introduced by Facebook in 2013, has rapidly become a dominant library for building modern and interactive user interfaces. Known for its component-based architecture, React allows developers to create reusable UI components, promoting a modular and maintainable code structure. Its virtual DOM efficiently updates and renders only the necessary parts of the user interface, contributing to enhanced performance. Widely adopted in single-page applications and complex user interfaces, React seamlessly integrates with other libraries and frameworks. Supported by a robust community and a wealth of third-party libraries, React has become a go-to choice for front-end development, empowering developers to create dynamic and responsive web applications efficiently.",
    link: "https://react.dev/",
  },
  {
    name: "Vue JS",
    photo: Vue,
    details:
      "Vue.js, introduced in 2014, has rapidly gained popularity as a progressive JavaScript framework for building user interfaces. Created by Evan You, Vue.js is known for its simplicity, flexibility, and ease of integration. With a component-based architecture similar to React, Vue.js allows developers to build modular and reusable UI components. Its approachable learning curve makes it suitable for both beginners and experienced developers. Vue.js excels in providing a flexible and reactive data-binding system, enabling efficient updates to the user interface. The framework's adaptability allows it to be integrated into projects gradually, making it a versatile choice for both small-scale applications and large, complex projects. Backed by an active and supportive community, Vue.js continues to be a popular option for front-end development, offering an intuitive and enjoyable development experience. Learn more about Vue.js: https://vuejs.org/",
    link: "https://vuejs.org/",
  },
  {
    name: "TensorFlow",
    photo: Tensorflow,
    details:
      "TensorFlow, developed by the Google Brain team, is an open-source machine learning framework widely used for building and training deep learning models. With its flexible architecture and extensive set of tools, TensorFlow supports various applications, including computer vision, natural language processing, and reinforcement learning. Its ecosystem includes high-level APIs for easy model development, as well as lower-level APIs for fine-tuning and research. TensorFlow's widespread adoption and active community make it a cornerstone in the field of machine learning.",
    link: "https://www.tensorflow.org/",
  },
  {
    name: "PyTorch",
    photo: Pytorch,
    details:
      "PyTorch, developed by Facebook, is a popular open-source deep learning framework known for its dynamic computational graph and ease of use. Widely used in research and production, PyTorch supports dynamic neural networks, making it flexible for experimentation and prototyping. It provides tools for building and training complex models with a focus on transparency and simplicity. PyTorch's strong community and contributions from both academia and industry have solidified its position as a preferred choice for many machine learning practitioners.",
    link: "https://pytorch.org/",
  },
  {
    name: "scikit-learn",
    photo: ScikitLearn,
    details:
      "scikit-learn is a simple yet efficient open-source machine learning library for Python. Built on NumPy, SciPy, and Matplotlib, scikit-learn provides tools for various machine learning tasks, including classification, regression, clustering, and dimensionality reduction. With a clean and consistent API, scikit-learn is accessible to both beginners and experienced practitioners. It is widely used in academia and industry for its ease of integration and comprehensive documentation.",
    link: "https://scikit-learn.org/",
  },
  {
    name: "Keras",
    photo: Keras,
    details:
      "Keras is an open-source high-level neural networks API, written in Python and capable of running on top of TensorFlow, Theano, and Microsoft Cognitive Toolkit (CNTK). It allows for easy and fast prototyping, supporting both convolutional and recurrent neural networks. Keras is widely used for its user-friendly interface and seamless integration with popular deep learning frameworks.",
    link: "https://keras.io/",
  },
  {
    name: "OpenCV",
    photo: OpenCV,
    details:
      "OpenCV (Open Source Computer Vision Library) is an open-source computer vision and machine learning software library. It provides a comprehensive set of tools for image processing, computer vision, and machine learning tasks. OpenCV is widely used in applications ranging from facial recognition to robotics due to its extensive set of functions and cross-platform support.",
    link: "https://opencv.org/",
  },
  {
    name: "Google Cloud",
    photo: googleCloud,
    details:
      "Google Cloud is a comprehensive cloud computing platform developed by Google, offering a wide range of services for computing, storage, data analytics, machine learning, and more. It provides scalable and flexible solutions for businesses and developers to build, deploy, and manage applications on Google's infrastructure. Google Cloud services include virtual machines, storage options, databases, machine learning APIs, and tools for big data processing. With a global network and a variety of integrated services, Google Cloud is a popular choice for businesses looking to leverage the power of the cloud for their applications and data.",
    link: "https://cloud.google.com/",
  },
  {
    name: "Stripe",
    photo: stripe,
    details:
      "Stripe is a leading online payment processing platform that enables businesses to accept payments over the internet. Known for its simplicity and developer-friendly approach, Stripe provides a set of APIs and tools that allow businesses to integrate secure and customizable payment solutions into their websites or applications. Stripe supports a variety of payment methods, including credit cards, digital wallets, and ACH transfers. It also offers features such as subscription billing, fraud prevention, and real-time analytics, making it a preferred choice for businesses of all sizes seeking a reliable and flexible payment processing solution.",
    link: "https://stripe.com/",
  },
  {
    name: "Google Auth",
    photo: googleAuth,
    details:
      "Google Auth, short for Google Authentication, refers to the authentication services provided by Google. It allows users to sign in to applications securely using their Google credentials. This authentication method is widely used to enhance the security of web and mobile applications by leveraging Google's robust user authentication system. Developers can integrate Google Auth into their applications using various authentication protocols such as OAuth and OpenID Connect, providing users with a convenient and trusted way to access services while maintaining account security.",
    link: "https://developers.google.com/identity",
  },
  {
    name: "Firebase",
    photo: firebase,
    details:
      "Firebase is a comprehensive mobile and web application development platform acquired by Google. It offers a range of services, including real-time database, authentication, hosting, cloud functions, and more. Firebase simplifies the development process by providing a unified platform for building, deploying, and scaling applications. Developers can leverage Firebase to build applications with real-time updates, user authentication, and seamless cloud integration. Its ease of use and scalability make Firebase a popular choice for startups and developers looking to quickly prototype and deploy applications.",
    link: "https://firebase.google.com/",
  },
  {
    name: "React Native",
    photo: React,
    details:
      "React Native is an open-source framework developed by Facebook for building cross-platform mobile applications using JavaScript and React. It allows developers to write code once and deploy it on both iOS and Android platforms, saving time and resources. React Native uses a native rendering engine to deliver high-performance and responsive mobile applications. With a large and active community, React Native has become a popular choice for developers looking to create mobile apps with a single codebase while maintaining a native look and feel.",
    link: "https://reactnative.dev/",
  },
  {
    name: "Swift",
    photo: swift,
    details:
      "Swift is a powerful and intuitive programming language developed by Apple for building applications on iOS, macOS, watchOS, and tvOS. Known for its speed, safety, and modern syntax, Swift is designed to make it easier for developers to write clean and efficient code. It is the preferred language for developing iOS applications and is seamlessly integrated with Apple's Xcode IDE. Swift supports a wide range of programming paradigms, making it versatile for building anything from simple mobile apps to complex, high-performance software.",
    link: "https://developer.apple.com/swift/",
  },
  {
    name: "Java",
    photo: java,
    details:
      "Java is a widely-used, object-oriented programming language known for its platform independence and versatility. Developed by Sun Microsystems (now owned by Oracle), Java is used for building a variety of applications, including web applications, mobile applications (Android), enterprise-level software, and more. Java's 'Write Once, Run Anywhere' philosophy allows developers to write code that can run on different platforms without modification. It has a large ecosystem of libraries and frameworks, making it a popular choice for developers across various domains.",
    link: "https://www.java.com/",
  },
  {
    name: "Twilio",
    photo: twilio,
    details:
      "Twilio is a cloud communications platform that provides APIs for developers to embed voice, messaging, and video capabilities into applications. Twilio's services make it easy for developers to integrate communication features, such as sending SMS messages, making voice calls, and handling two-factor authentication. With its developer-friendly APIs and extensive documentation, Twilio is widely used to enhance applications with real-time communication features. It is commonly employed in industries like customer service, healthcare, and finance to create interactive and engaging user experiences.",
    link: "https://www.twilio.com/",
  },
];
