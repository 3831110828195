import React from "react";
import "../Assets/Styles/endSection.css";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const EndSection = () => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate("/contact");
    document.getElementById("myNav").style.height = "0%";
  };

  return (
    <div className="end-section">
      <div className="section">
        <div className="section-content">
          <h3>
            Curiosity sparked? Let's chat about innovative solutions for you.
          </h3>
          <p>
            Explore the transformative potential of CouSync as we leverage
            cutting-edge technology to elevate your projects.
          </p>
          <Button class="btn-touch" onClick={() => handleLinkClick()}>
            Let's Start Discussing {"-->"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EndSection;
