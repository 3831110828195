import React, { useEffect } from "react";
import "../Assets/Styles/about.css";
import screen from "../Assets/Images/about-us-screen.png";
import { FiCheckCircle } from "react-icons/fi";
import { GiOpenBook } from "react-icons/gi";
import { TbTargetArrow } from "react-icons/tb";
import EndSection from "../Components/endSection";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about">
      <h1 className="title">About Us</h1>
      <h2 className="subtitle">
        Innovative IT Solutions for a Connected Future.
      </h2>
      <div className="line-sep"></div>
      <div className="about-body">
        <div className="top">
          <div className="left">
            <p>
              In the bustling realm of technology, where innovation shapes the
              future, CouSync (Pvt) Ltd emerges as a beacon of transformative
              brilliance. Our journey is not just about coding lines or crafting
              algorithms; it's a narrative woven with passion, resilience, and
              an unyielding commitment to redefining possibilities.
            </p>
            <p>
              Founded with a vision to bridge the gap between imagination and
              implementation, CouSync embarked on its odyssey in the heart of
              Lahore. From the outset, our team, fueled by the spirit of
              curiosity, delved into the intricate world of artificial
              intelligence, machine learning, and software development.
            </p>
            <p>
              What sets us apart is not merely the lines of code we write, but
              the stories we unravel through our projects. Each endeavor is a
              testament to our dedication to creating impactful,
              forward-thinking solutions.
            </p>
          </div>
          <div className="right">
            <p>
              From conceptualization to execution, we thrive on challenges,
              transforming ideas into tangible, cutting-edge realities.
            </p>
            <p>
              At the core of our philosophy is a belief in the power of
              collaboration. CouSync is not just a company; it's a community of
              diverse talents, each contributing a unique note to the symphony
              of innovation. We celebrate creativity, embrace challenges, and
              relish the joy of turning complex problems into elegant solutions.
            </p>
            <p>
              As we navigate the ever-evolving landscape of technology, our
              commitment remains unwavering — to inspire, to innovate, and to
              empower. Join us on this journey, and together, let's shape a
              future where possibilities are boundless, and technology is a
              force for positive change. CouSync: Connecting Ideas, Creating
              Futures.
            </p>
          </div>
        </div>
        <div className="mid">
          <img src={screen} alt="about us screen"></img>
        </div>
        <div className="bot">
          <div className="bot-section">
            <div className="bot-section-head">
              <div className="bot-section-logo">
                <GiOpenBook size={50} />
              </div>
              <div>
                <h2>&nbsp;Our Story</h2>
              </div>
            </div>
            <div className="bot-section-body">
              <p>
                In the heart of Lahore, CouSync (Pvt) Ltd began its journey as a
                passionate endeavor to redefine the landscape of technology.
                Established by a team of visionary innovators, our story is
                woven with threads of curiosity, resilience, and a relentless
                pursuit of pushing the boundaries of what's possible. From the
                very inception, we set out to not just write code but to tell
                stories through our projects. Each line of code represents a
                chapter, narrating our dedication to transforming imaginative
                concepts into tangible, cutting-edge solutions.
              </p>
            </div>
          </div>
          <div className="bot-section">
            <div className="bot-section-head">
              <div className="bot-section-logo">
                <TbTargetArrow size={50} />
              </div>
              <div>
                <h2>&nbsp;Our Mission</h2>
              </div>
            </div>
            <div className="bot-section-body">
              <p>
                At CouSync, our mission is to bridge the chasm between
                imagination and implementation. We believe in the transformative
                power of technology to shape a better future. Fueled by a
                commitment to innovation, collaboration, and continuous
                learning, we strive to create impactful solutions that not only
                meet the needs of today but also anticipate the challenges of
                tomorrow. Our mission is to inspire, empower, and pioneer a new
                era where technology becomes a force for positive change.
              </p>
            </div>
          </div>
          <div className="bot-section">
            <div className="bot-section-head">
              <div className="bot-section-logo">
                <FiCheckCircle size={50} />
              </div>
              <div>
                <h2>&nbsp;Our Success</h2>
              </div>
            </div>
            <div className="bot-section-body">
              <p>
                The success of CouSync is not merely measured in lines of code
                or completed projects; it's defined by the impact we create. Our
                success lies in the stories of clients who have seen their
                visions materialize into reality, thanks to our innovative
                solutions. It's in the collaborative spirit of our team, where
                diverse talents converge to overcome challenges and turn ideas
                into elegant solutions. As we reflect on our journey, success is
                not a destination; it's a continuous exploration, an ongoing
                narrative of pushing boundaries and crafting a future where
                technology is synonymous with positive transformation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <EndSection />
    </div>
  );
}

export default About;
