import React, { useEffect } from "react";
import Home from "../Pages/home";
import OurServices from "../Pages/ourServices";
import Portfolio from "../Pages/portfolio";
import ContactUs from "../Pages/contactUs";

import { Route, Routes, useLocation } from "react-router-dom";
import Careers from "../Pages/careers";
import About from "../Pages/About";
import Technologies from "../Pages/Technologies";

import ReactGA from "react-ga4";

const Router = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </div>
  );
};

export default Router;
