import React, { useEffect } from "react";
import "../Assets/Styles/ourServices.css";
import { Services } from "../Utils/services";
import EndSection from "../Components/endSection";

const OurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="our-services">
      <h1 className="title">Our Services</h1>
      <h2 className="subtitle">
        At your service, where excellence meets exceptional!
      </h2>
      <div className="line-sep" />

      <div className="container">
        <div className="col-1">
          <div className="card">
            <h1>{Services[0]?.label}</h1>
            <div className="card-body">
              <img
                src={Services[0]?.photo}
                alt={Services[0]?.label}
                width={"100%"}
              />
              <p>{Services[0]?.information}</p>
            </div>
          </div>
          <div className="card">
            <h1>{Services[1]?.label}</h1>
            <div className="card-body">
              <img
                src={Services[1]?.photo}
                alt={Services[1]?.label}
                width={"100%"}
              />
              <p>{Services[1]?.information}</p>
            </div>
          </div>

          <div className="card">
            <h1>{Services[2]?.label}</h1>
            <div className="card-body-2">
              <img
                src={Services[2]?.photo}
                alt={Services[2]?.label}
                width={"50%"}
              />
              <p>{Services[2]?.information}</p>
            </div>
          </div>

          <div className="card">
            <h1>{Services[3]?.label}</h1>
            <div className="card-body-2">
              <img
                src={Services[3]?.photo}
                alt={Services[3]?.label}
                width={"50%"}
              />
              <p>{Services[3]?.information}</p>
            </div>
          </div>

          <div className="card">
            <h1>{Services[4]?.label}</h1>
            <div className="card-body-2">
              <img
                src={Services[4]?.photo}
                alt={Services[4]?.label}
                width={"50%"}
              />
              <p>{Services[4]?.information}</p>
            </div>
          </div>
        </div>

        <div className="col-2">
          <div className="card">
            <h1>{Services[5]?.label}</h1>
            <div className="card-body-2">
              <img
                src={Services[5]?.photo}
                alt={Services[5]?.label}
                width={"50%"}
              />
              <p>{Services[5]?.information}</p>
            </div>
          </div>
          <div className="card">
            <h1>{Services[6]?.label}</h1>
            <div className="card-body-2">
              <img
                src={Services[6]?.photo}
                alt={Services[6]?.label}
                width={"50%"}
              />
              <p>{Services[6]?.information}</p>
            </div>
          </div>

          <div className="card">
            <h1>{Services[7]?.label}</h1>
            <div className="card-body-2">
              <img
                src={Services[7]?.photo}
                alt={Services[7]?.label}
                width={"50%"}
              />
              <p>{Services[7]?.information}</p>
            </div>
          </div>

          <div className="card">
            <h1>{Services[8]?.label}</h1>
            <div className="card-body">
              <img
                src={Services[8]?.photo}
                alt={Services[8]?.label}
                width={"100%"}
              />
              <p>{Services[8]?.information}</p>
            </div>
          </div>

          <div className="card">
            <h1>{Services[9]?.label}</h1>
            <div className="card-body">
              <img
                src={Services[9]?.photo}
                alt={Services[9]?.label}
                width={"100%"}
              />
              <p>{Services[9]?.information}</p>
            </div>
          </div>
        </div>
      </div>

      <EndSection />
    </div>
  );
};

export default OurServices;
