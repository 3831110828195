import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import companyLogo from "../../Assets/Images/white and purple.png";

function Footer({ setSelectedLink }) {
  const navigate = useNavigate();

  const handleLinkClick = (e) => {
    navigate(e);
    setSelectedLink(e);
    document.getElementById("myNav").style.height = "0%";
  };
  return (
    <div className="footer">
      <h2 className="title">
        <img src={companyLogo} alt="Company Logo" />
      </h2>
      {/* <p>
        At CouSync, our exceptional developers turn visions into reality. With
        unrivaled expertise, we tackle any software challenge, crafting
        innovative solutions that redefine the future of development. Join us on
        the journey where your ideas come to life with the dynamic force of
        CouSync.
      </p> */}
      <div className="navigation">
        <div className="gutter-row" onClick={() => handleLinkClick("/home")}>
          <div className="item">HOME</div>
        </div>
        <div className="gutter-row" onClick={() => handleLinkClick("/about")}>
          <div className="item">ABOUT</div>
        </div>
        <div
          className="gutter-row"
          onClick={() => handleLinkClick("/services")}
        >
          <div className="item">SERVICES</div>
        </div>
        <div
          className="gutter-row"
          onClick={() => handleLinkClick("/technologies")}
        >
          <div className="item">TECHNOLOGIES</div>
        </div>
        <div
          className="gutter-row"
          onClick={() => handleLinkClick("/portfolio")}
        >
          <div className="item">PORTFOLIO</div>
        </div>
        <div className="gutter-row" onClick={() => handleLinkClick("/careers")}>
          <div className="item">CAREERS</div>
        </div>
      </div>

      <div className="icons">
        <ul>
          <li>
            <Link
              to="https://www.linkedin.com/company/cousync-private-limited/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-cont"
            >
              <FaLinkedin />
            </Link>
          </li>
          <li>
            <Link className="icon-cont">
              <FaInstagram />
            </Link>
          </li>
        </ul>
      </div>

      <div className="rights">
        <p>
          Copyright © 2024. All Rights Reserved | Engineered with 🧠 by the
          Visionaries at{" "}
          <span style={{ color: "#666EDD", fontWeight: "bold" }}>CouSync</span>
        </p>
      </div>
    </div>
  );
}

export default Footer;
