import React, { useEffect } from "react";
import "../Assets/Styles/careers.css";
import { Button, Form, Input, Select, Tooltip, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import careerImage from "../Assets/Images/career page image.png";
import EndSection from "../Components/endSection";

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const onFinish = (values) => {
    console.log(values);
  };

  const scrollToForm = () => {
    const formSection = document.getElementById("apply-form");
    if (formSection) {
      formSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  React.useEffect(() => {
    document.querySelector(".career .join-us").classList.add("animate");
    document.querySelector(".career .join-us-image").classList.add("animate");
  }, []);

  const props = {
    name: "file",
    maxCount: 1,
    multiple: false,
    accept: ".pdf",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div className="career">
      <h1 className="title">Careers</h1>
      <h2 className="subtitle">
        Unlock Your Potential: A Journey into Cutting-edge IT Solutions with
        CouSync.
      </h2>
      <div className="line-sep"></div>

      <div className="page-content">
        <div className="left">
          <div className="join-us-image">
            <img src={careerImage} alt="Career Page" />
          </div>
          <div className="join-us">
            <section className="benefits-section">
              <h2 className="section-title">Why Join Us?</h2>
              <ul className="benefits-list">
                <li>
                  Joining our team opens up exciting opportunities for
                  professional growth in a collaborative and innovative work
                  environment. We offer competitive compensation and benefits,
                  along with flexible work hours and remote work options to
                  support your work-life balance. Our commitment to your success
                  extends to ongoing training and development opportunities,
                  fostering a culture of diversity and inclusion. At our
                  company, outstanding performance is not only valued but also
                  recognized and rewarded. Explore a fulfilling career with us,
                  where your potential is nurtured and celebrated.
                </li>
              </ul>
              <div className="button-holder">
                <Button onClick={scrollToForm} className="button-apply">
                  Apply Now
                </Button>
              </div>
            </section>
          </div>
        </div>
        <div id="apply-form"></div>
        <div className="right">
          <div className="message-box">
            <h2>Forge Your Career: Apply Now!</h2>
            <Form onFinish={onFinish} layout="vertical">
              <Form.Item
                label="Job Title"
                name="jobTitle"
                rules={[
                  {
                    required: true,
                    message: "Please select your desired job title.",
                  },
                ]}
              >
                <Select placeholder="Select Job Title">
                  <Option value="designer">Creative Digital Designer</Option>
                  <Option value="designer">Business Developer</Option>
                </Select>
              </Form.Item>
              <div className="form-inline-items">
                <div className="form-inline-item">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name." },
                    ]}
                  >
                    <Input placeholder="Your Name" />
                  </Form.Item>
                </div>
                <div className="form-inline-item">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email." },
                      { type: "email", message: "Please enter a valid email." },
                    ]}
                  >
                    <Input placeholder="Your Email" />
                  </Form.Item>
                </div>
              </div>
              <div className="form-inline-items">
                <div className="form-inline-item">
                  <Form.Item
                    label="Contact"
                    name="contact"
                    rules={[
                      { required: true, message: "Please enter your contact." },
                    ]}
                  >
                    <Tooltip title="Enter a valid phone number use 00 instead of +">
                      <Input
                        type="tel"
                        pattern="[0-9]*"
                        placeholder="Your Contact"
                      />
                    </Tooltip>
                  </Form.Item>
                </div>
                <div className="form-inline-item">
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      { required: true, message: "Please enter your address." },
                    ]}
                  >
                    <Tooltip title="Include: Area, City, Country Only!">
                      <Input placeholder="Your Address" />
                    </Tooltip>
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                label="Resume Upload"
                name="resume"
                rules={[
                  { required: true, message: "Please upload your resume." },
                ]}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: "#7c7c7c" }} />
                  </p>
                  <p className="ant-upload-text" style={{ color: "#7c7c7c" }}>
                    Click or drag a PDF file to this area to upload only one
                    file allowed
                  </p>
                  <p className="ant-upload-hint" style={{ color: "#7c7c7c" }}>
                    Strictly prohibited from uploading company data or other
                    banned files.
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item
                label="Cover Letter"
                name="coverLetter"
                rules={[
                  {
                    required: true,
                    message: "Please enter your cover letter.",
                  },
                  {
                    max: 10,
                    message:
                      "Maximum 1000 characters allowed for the cover letter.",
                  },
                ]}
              >
                <TextArea
                  placeholder="Type your Cover Letter here..."
                  rows={4}
                />
              </Form.Item>
              <Form.Item>
                <div className="center">
                  <Button type="primary" htmlType="submit" className="send-btn">
                    Send
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
